import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import zhCN from "./zhCN";

Vue.use(VueI18n);

const defaultLocale = "zhCN";

const i18n = new VueI18n({
  // legacy: false,
  // globalInjection: true,
  locale: defaultLocale,
  messages: {
    en,
    zhCN
  }
});

export default i18n;
