/* eslint-disable */
const messages = {
  host: "主持",
  Host: "主持人",
  players: "玩家",
  Players: "玩家",
  join: "加入",
  Join: "加入",
  player: "玩家",
  storyTeller: "说书人",
  playing: "玩",
  hosting: "主持",
  Add: "增加座位",
  Characters: "角色",
  Help: "帮助",
  Grimoire: "魔典",
  Countdown: "倒计时",
  Restart: "重启",
  Start: "启动",
  Pause: "暂停",
  Resume: "恢复",
  Reset: "重置",
  Close: "关闭",
  FirstNight: "首夜",
  OtherNights: "其它夜晚",
  DefaultPlayerName: "空座位",
  Execution: "处决",
  editions: {
    tb: {
      "name": "暗流涌动",
      "author": "The Pandemonium Institute",
      "description": "Clouds roll in over Ravenswood Bluff, engulfing this sleepy town and its superstitious inhabitants in foreboding shadow. Freshly-washed clothes dance eerily on lines strung between cottages. Chimneys cough plumes of smoke into the air. Exotic scents waft through cracks in windows and under doors, as hidden cauldrons lay bubbling. An unusually warm Autumn breeze wraps around vine-covered walls and whispers ominously to those brave enough to walk the cobbled streets.\n\nAnxious mothers call their children home from play, as thunder begins to clap on the horizon. If you listen more closely, however, noises stranger still can be heard echoing from the neighbouring forest. Under the watchful eye of a looming monastery, silhouetted figures skip from doorway to doorway. Those who can read the signs know there is... Trouble Brewing.",
      "level": "Beginner",
      "roles": [],
      "isOfficial": true
    },
    bmr: {
      "name": "黯月初升",
      "author": "The Pandemonium Institute",
      "description": "The sun is swallowed by a jagged horizon as another winter's day surrenders to the night. Flecks of orange and red decay into deeper browns, the forest transforming in silent anticipation of the coming snow.\n\nRavenous wolves howl from the bowels of a rocky crevasse beyond the town borders, sending birds scattering from their cozy rooks. Travelers hurry into the inn, seeking shelter from the gathering chill. They warm themselves with hot tea, sweet strains of music and hearty ale, unaware that strange and nefarious eyes stalk them from the ruins of this once great city.\n\nTonight, even the livestock know there is a... Bad Moon Rising.",
      "level": "Intermediate",
      "roles": [],
      "isOfficial": true
    },
    snv: {
      "name": "梦陨春宵",
      "author": "The Pandemonium Institute",
      "description": "Vibrant spring gives way to a warm and inviting summer. Flowers of every description blossom as far as the eye can see, tenderly nurtured in public gardens and window boxes overlooking the lavish promenade. Birds sing, artists paint and philosophers ponder life's greatest mysteries inside a bustling tavern as a circus pitches its endearingly ragged tent on the edge of town.\n\nAs the townsfolk bask in frivolity and mischief, indulging themselves in fine entertainment and even finer wine, dark and clandestine forces are assembling. Witches and cults lurk in majestic ruins on the fringes of the community, hosting secret meetings in underground caves and malevolently plotting the downfall of Ravenswood Bluff and its revelers.\n\nThe time is ripe for... Sects & Violets.",
      "level": "Intermediate",
      "roles": [],
      "isOfficial": true
    },
    luf: {
      "name": "Laissez un Faire",
      "author": "The Pandemonium Institute",
      "description": "",
      "level": "Veteran",
      "roles": ["balloonist", "savant", "amnesiac", "fisherman", "artist", "cannibal", "mutant", "lunatic", "widow", "goblin", "leviathan"],
      "isOfficial": true
    }
  },
  fabled: {
    doomsayer: {
      "id": "doomsayer",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "name": "末日预言者",
      "team": "fabled",
      "ability": "如果大于等于4名玩家存存活，每名当前存活玩家可以公开要求杀死一名与他同阵营的玩家。（每名玩家限一次）"
    },
    angel: {
      "id": "angel",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": ["Protect", "Something Bad"],
      "setup": false,
      "name": "天使",
      "team": "fabled",
      "ability": "对新玩家的死亡负最大责任，一些不好的事情可能发生到你的身上。"
    },
    buddhist: {
      "id": "buddhist",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "name": "佛教徒",
      "team": "fabled",
      "ability": "每个白天的前两分钟玩家不能发言"
    },
    hellslibrarian: {
      "id": "hellslibrarian",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": ["Something Bad"],
      "setup": false,
      "name": "地狱藏书员",
      "team": "fabled",
      "ability": "当说书人宣布安静时，仍在说话的玩家可能遭遇一些不好的事情"
    },
    revolutionary: {
      "id": "革命者",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": ["Used"],
      "setup": false,
      "name": "Revolutionary",
      "team": "fabled",
      "ability": "公开声明一对相邻玩家为同一阵营。每局游戏限一次，他们中的一人可能被当做其它角色阵营"
    },
    fiddler: {
      "id": "fiddler",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "name": "小提琴手",
      "team": "fabled",
      "ability": "每局游戏限一次，恶魔可以秘密选择一名对立阵营的玩家，所有玩家要表决： 这两名玩家中谁的阵营获胜（平局恶魔获胜）"
    },
    toymaker: {
      "id": "toymaker",
      "firstNightReminder": "",
      "otherNight": 1,
      "otherNightReminder": "If it is a night when a Demon attack could end the game, and the Demon is marked “Final night: No Attack,” then the Demon does not act tonight. (Do not wake them.)",
      "reminders": ["Final Night: No Attack"],
      "setup": false,
      "name": "玩具匠人",
      "team": "fabled",
      "ability": "恶魔可以选择在夜晚放弃攻击（每局游戏至少一次）邪恶玩家照常获取初始信息"
    },
    fibbin: {
      "id": "fibbin",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": ["Used"],
      "setup": false,
      "name": "骗人精",
      "team": "fabled",
      "ability": "游戏限一次，一名善良玩家可能得到错误信息"
    },
    duchess: {
      "id": "duchess",
      "firstNightReminder": "",
      "otherNight": 1,
      "otherNightReminder": "Wake each player marked “Visitor” or “False Info” one at a time. Show them the Duchess token, then fingers (1, 2, 3) equaling the number of evil players marked “Visitor” or, if you are waking the player marked “False Info,” show them any number of fingers except the number of evil players marked “Visitor.”",
      "reminders": ["Visitor", "False Info"],
      "setup": false,
      "name": "公爵夫人",
      "team": "fabled",
      "ability": "每个白天，三名玩家可以选择拜访你。在夜晚， 每个拜访者会得知其中有几个邪恶玩家，但有一个人会得知错误信息"
    },
    sentinel: {
      "id": "sentinel",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": [],
      "setup": true,
      "name": "哨兵",
      "team": "fabled",
      "ability": "初始设置时，可能会额外增加或减少一个外来者"
    },
    spiritofivory: {
      "id": "spiritofivory",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": ["No extra evil"],
      "setup": false,
      "name": "圣洁之魂",
      "team": "fabled",
      "ability": "游戏过程中，邪恶玩家的总数最多能比初始设置多一名。"
    },
    djinn: {
      "id": "djinn",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "name": "灯神",
      "team": "fabled",
      "ability": "使用灯神的相克规则，所有玩家都会知道其内容。"
    },
    stormcatcher: {
      "id": "stormcatcher",
      "firstNight": 1,
      "firstNightReminder": "Mark a good player as \"Safe\". Wake each evil player and show them the marked player.",
      "otherNightReminder": "",
      "reminders": ["Safe"],
      "setup": false,
      "name": "暴风捕手",
      "team": "fabled",
      "ability": "游戏开始时你你要宣布一个善良角色。如果该角色在场，他只能死于处决。但所有邪恶玩家会在首个夜晚得知他是哪一位玩家。"
    },
    deusexfiasco: {
      "id": "deusexfiasco",
      "firstNightReminder": "",
      "otherNightReminder": "",
      "reminders": ["Whoops"],
      "setup": false,
      "name": "失败的上帝",
      "team": "fabled",
      "ability": "每局游戏限一次，说书人可能会犯一个错误（真个一个吗？）但是会在发现犯错后立即更正并公开承认自己处理有误。"
    }
  },
  hatred: {

  },
  teams: {
    townsfolk: "镇民",
    outsider: "外来者",
    minion: "爪牙",
    demon: "恶魔"
  },
  roles: {
    washerwoman: {
      "id": "washerwoman",
      "name": "洗衣妇",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 32,
      "firstNightReminder": "展示游戏中一名镇民的角色标记。指向两名玩家，其中一名是那名角色",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Townsfolk",
          "Wrong"],
      "setup": false,
      "ability": "在你的首个夜晚，你会得知两名玩家和一个镇民角色：这两名玩家之一是该角色。"
    },
    librarian: {
      "id": "librarian",
      "name": "图书管理员",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 33,
      "firstNightReminder": "展示游戏中一名外来者的角色。指向两名玩家，其中一名是那名角色",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Outsider",
          "Wrong"],
      "setup": false,
      "ability": "在你的首个夜晚，你会得知两名玩家和一个外来者角色：这两名玩家之一是该角色（或者你会得知没有外来者在场）。"
    },
    investigator: {
      "id": "investigator",
      "name": "调查员",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 34,
      "firstNightReminder": "展示一名爪牙的角色。指向两名玩家，其中一名是那名角色",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Minion",
          "Wrong"],
      "setup": false,
      "ability": "在你的首个夜晚，你会得知两名玩家和一个爪牙角色：这两名玩家之一是该角色（或者你会得知没有爪牙在场）。"
    },
    chef: {
      "id": "chef",
      "name": "厨师",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 35,
      "firstNightReminder": "手展示数字，表明有几对相邻的邪恶方玩家",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "在你的首个夜晚，你会得知场上邻座的邪恶玩家有多少对。"
    },
    empath: {
      "id": "empath",
      "name": "共情者",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 36,
      "firstNightReminder": "展示数字，表明共情者邻座中有几名存活的邪恶方玩家",
      "otherNight": 53,
      "otherNightReminder": "Show the finger signal (0, 1, 2) for the number of evil neighbours.",
      "reminders": [],
      "setup": false,
      "ability": "每个夜晚，你会得知与你邻近的两名存活的玩家中邪恶玩家的数量。"
    },
    fortuneteller: {
      "id": "fortuneteller",
      "name": "占卜师",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 37,
      "firstNightReminder": "占卜师指向两位玩家，给出是或否，表明其中是否有恶魔",
      "otherNight": 54,
      "otherNightReminder": "占卜师指向两位玩家，给出是或否，表明其中是否有恶魔",
      "reminders": ["Red herring"],
      "setup": false,
      "ability": "每个夜晚，你要选择两名玩家：你会得知他们之中是否有恶魔。会有一名善良玩家始终被你的能力当作恶魔。"
    },
    undertaker: {
      "id": "undertaker",
      "name": "送葬人",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 56,
      "otherNightReminder": "如果一名玩家被处决，展示那名玩家的身份。",
      "reminders": ["Executed"],
      "setup": false,
      "ability": "每个夜晚*，你会得知今天白天死于处决的玩家的身份。"
    },
    monk: {
      "id": "monk",
      "name": "僧侣",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 13,
      "otherNightReminder": "The previously protected player is no longer protected. The Monk points to a player not themself. Mark that player 'Protected'.",
      "reminders": ["Protected"],
      "setup": false,
      "ability": "每个夜晚*，你要选择除你以外的一名玩家：当晚恶魔的负面能力对他无效。"
    },
    ravenkeeper: {
      "id": "ravenkeeper",
      "name": "守鸦人",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 42,
      "otherNightReminder": "如果守鸦人今夜死亡： 守鸦人可以指向一名玩家，展示此玩家的身份",
      "reminders": [],
      "setup": false,
      "ability": "如果你在夜晚死亡，你会被唤醒，然后你要选择一名玩家：你会得知他的角色。"
    },
    virgin: {
      "id": "virgin",
      "name": "贞洁者",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "当你首次被提名时，如果提名你的玩家是镇民，他立刻被处决。"
    },
    slayer: {
      "id": "slayer",
      "name": "猎手",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "每局游戏限一次，你可以在白天时公开选择一名玩家：如果他是恶魔，他死亡。"
    },
    soldier: {
      "id": "soldier",
      "name": "士兵",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "恶魔的负面能力对你无效。"
    },
    mayor: {
      "id": "mayor",
      "name": "市长",
      "edition": "tb",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "如果只有三名玩家存活且白天没有⼈被处决，你的阵营获胜。如果你在夜晚即将死亡，可能会有一名其他玩家代替你死亡。"
    },
    butler: {
      "id": "butler",
      "name": "管家",
      "edition": "tb",
      "team": "outsider",
      "firstNight": 38,
      "firstNightReminder": "管家指向一名玩家，标记此玩家为主人",
      "otherNight": 55,
      "otherNightReminder": "管家指向一名玩家，标记此玩家为主人",
      "reminders": ["Master"],
      "setup": false,
      "ability": "每个夜晚，你要选择除你以外的一名玩家：明天白天，只有他投票时你才能投票。"
    },
    drunk: {
      "id": "drunk",
      "name": "酒鬼",
      "edition": "tb",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "remindersGlobal": ["Drunk"],
      "setup": true,
      "ability": "你不知道你是酒鬼。你以为你是一个镇民角色，但其实你不是。"
    },
    recluse: {
      "id": "recluse",
      "name": "隐士",
      "edition": "tb",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "你可能会被当作邪恶阵营、爪牙角色或恶魔角色，即使你已死亡。"
    },
    saint: {
      "id": "saint",
      "name": "圣徒",
      "edition": "tb",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "如果你死于处决，你的阵营落败。"
    },
    poisoner: {
      "id": "poisoner",
      "name": "投毒者",
      "edition": "tb",
      "team": "minion",
      "firstNight": 17,
      "firstNightReminder": "投毒者指向一名玩家，该玩家中毒",
      "otherNight": 8,
      "otherNightReminder": "此前中毒玩家不再中毒，可继续给一名玩家投毒",
      "reminders": ["Poisoned"],
      "setup": false,
      "ability": "每个夜晚，你要选择一名玩家：他在当晚和明天白天中毒。"
    },
    spy: {
      "id": "spy",
      "name": "间谍",
      "edition": "tb",
      "team": "minion",
      "firstNight": 48,
      "firstNightReminder": "只要间谍需要随时可向间谍展示魔典",
      "otherNight": 68,
      "otherNightReminder": "只要间谍需要随时可向间谍展示魔典",
      "reminders": [],
      "setup": false,
      "ability": "每个夜晚，你能查看魔典。你可能会被当作善良阵营、镇民角色或外来者角色，即使你已死亡。"
    },
    scarletwoman: {
      "id": "scarletwoman",
      "name": "荡妇",
      "edition": "tb",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 20,
      "otherNightReminder": "如果荡妇今天变成恶魔： （说书人）对她展示'你是', 并给与恶魔身份",
      "reminders": ["Demon"],
      "setup": false,
      "ability": "如果大于等于五名玩家存活时（旅行者不计算在内）恶魔死亡，你变成那个恶魔。"
    },
    baron: {
      "id": "baron",
      "name": "男爵",
      "edition": "tb",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": true,
      "ability": "会有额外的外来者在场。[+2 外来者]"
    },
    imp: {
      "id": "imp",
      "name": "小恶魔",
      "edition": "tb",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 24,
      "otherNightReminder": "小恶魔指向一名玩家，该玩家死亡。如果指向他自己， 将一名爪牙角色替换为小恶魔。（说书人）向其告知他的‘小恶魔’身份",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "每个夜晚*，你要选择一名玩家：他死亡。如果你以这种方式自杀，一名爪牙会变成小恶魔。"
    },
    bureaucrat: {
      "id": "bureaucrat",
      "name": "官僚",
      "edition": "tb",
      "team": "traveler",
      "firstNight": 1,
      "firstNightReminder": "官僚指向一名玩家，放置其3倍投票权的标记给以这名玩家",
      "otherNight": 1,
      "otherNightReminder": "官僚指向一名玩家，放置其3倍投票权的标记给以这名玩家",
      "reminders": ["3 votes"],
      "setup": false,
      "ability": "每个夜晚，选择一名不是你的玩的，第二个白天他的投票等于3票。"
    },
    thief: {
      "id": "thief",
      "name": "贼",
      "edition": "tb",
      "team": "traveler",
      "firstNight": 1,
      "firstNightReminder": "贼指向一名玩家，放置其投票无效标记",
      "otherNight": 1,
      "otherNightReminder": "贼指向一名玩家，放置其投票无效标记",
      "reminders": ["Negative vote"],
      "setup": false,
      "ability": "每个夜晚，选择一名不是你的玩家，第二个白天他的投票失效。"
    },
    gunslinger: {
      "id": "gunslinger",
      "name": "枪手",
      "edition": "tb",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "每个白天，当首次投票被统计后，你可以选择一名投过票的玩家： 他死亡"
    },
    scapegoat: {
      "id": "scapegoat",
      "name": "替罪羊",
      "edition": "tb",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "如果你的阵营的一名玩家被处决，你可能会替代他被处决。"
    },
    beggar: {
      "id": "beggar",
      "name": "乞丐",
      "edition": "tb",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "你必须使用投票标记投票，死亡玩家科技将他的投票标记给你，如果他这么做你会得知他的阵营。你不会中毒和醉酒"
    },
    grandmother: {
      "id": "grandmother",
      "name": "祖母",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 39,
      "firstNightReminder": "向其展示身份，并选择标记玩家",
      "otherNight": 50,
      "otherNightReminder": "如果祖母标记的角色今夜死亡，你也死亡",
      "reminders": ["Grandchild"],
      "setup": false,
      "ability": "在你的首个夜晚，你会得知一名善良玩家和他的角色。如果恶魔杀死了他，你也会死亡。"
    },
    sailor: {
      "id": "sailor",
      "name": "水手",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 10,
      "firstNightReminder": "水手选择一名现存玩家，你两其中一个处于醉酒状态，直到下个夜晚",
      "otherNight": 4,
      "otherNightReminder": "水手选择一名现存玩家，你两其中一个处于醉酒状态，直到下个夜晚",
      "reminders": ["Drunk"],
      "setup": false,
      "ability": "每个夜晚，你要选择一名存活的玩家：你或他之一会醉酒直到下个黄昏。你不会死亡。"
    },
    chambermaid: {
      "id": "chambermaid",
      "name": "女仆",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 50,
      "firstNightReminder": "女仆指向两名玩家，展示其中有几个人处于清醒状态（技能生效）",
      "otherNight": 70,
      "otherNightReminder": "女仆指向两名玩家，展示其中有几个人处于清醒状态（技能生效）",
      "reminders": [],
      "setup": false,
      "ability": "每个夜晚，你要选择除你以外的两名存活的玩家：你会得知他们中有几人在当晚因其自身能力而被唤醒。"
    },
    exorcist: {
      "id": "exorcist",
      "name": "驱魔人",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 22,
      "otherNightReminder": "指向一名跟上一夜不同的玩家，如果该角色是恶魔，恶魔今晚无法行动",
      "reminders": ["Chosen"],
      "setup": false,
      "ability": "每个夜晚*，你要选择一名玩家（与上个夜晚不同）：如果你选中了恶魔，他会得知你是什么角色，但他当晚不会因其自身能力而被唤醒。"
    },
    innkeeper: {
      "id": "innkeeper",
      "name": "旅店老板",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 9,
      "otherNightReminder": "先前保护且醉酒的两名玩家失效。 指向两名玩家，他们不会死亡但其中一个会醉酒",
      "reminders": ["Protected",
          "Drunk"],
      "setup": false,
      "ability": "每个夜晚*，你要选择两名玩家：他们当晚不会死亡，但其中一人会醉酒到下个黄昏。"
    },
    gambler: {
      "id": "gambler",
      "name": "赌徒",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 11,
      "otherNightReminder": "指向一名玩家并猜测其角色，猜错则死亡。",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "每个夜晚*，你要选择一名玩家并猜测他的角色：如果你猜错了，你会死亡。"
    },
    gossip: {
      "id": "gossip",
      "name": "造谣者",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 47,
      "otherNightReminder": "如果公开声明是对的，选择一名不受死亡保护的玩家，该玩家死亡",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "每个白天，你可以公开发表一个声明。如果该声明正确，在当晚会有一名玩家死亡。"
    },
    courtier: {
      "id": "courtier",
      "name": "侍臣",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 19,
      "firstNightReminder": "侍臣或者不发动，或者指向一名角色名单的身份。若该角色在场，则处于醉酒状态",
      "otherNight": 10,
      "otherNightReminder": "减少你守夜指定的醉酒者天数，如果侍臣还没发动其能力，可以发动其能力",
      "reminders": ["Drunk 3",
          "Drunk 2",
          "Drunk 1",
          "No ability"],
      "setup": false,
      "ability": "每局游戏限一次，在夜晚时，你可以选择一个角色：如果该角色在场，该角色之一从当晚开始醉酒三天三夜。"
    },
    professor: {
      "id": "professor",
      "name": "教授",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 45,
      "otherNightReminder": "如果教授还没使用技能，可以拒绝发动或者选择发动技能",
      "reminders": ["Alive",
          "No ability"],
      "setup": false,
      "ability": "每局游戏限一次，在夜晚时*，你可以选择一名死亡的玩家：如果他是镇民，你会将他起死回生。"
    },
    minstrel: {
      "id": "minstrel",
      "name": "吟游诗人",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Everyone drunk"],
      "setup": false,
      "ability": "当一名爪牙死于处决时，除了你和旅行者以外的所有其他玩家醉酒直到明天黄昏。"
    },
    tealady: {
      "id": "tealady",
      "name": "茶艺师",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Can not die"],
      "setup": false,
      "ability": "如果与你邻近的两名存活的玩家是善良的，他们不会死亡。"
    },
    pacifist: {
      "id": "pacifist",
      "name": "和平主义者",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "被处决的善良玩家可能不会死亡。"
    },
    fool: {
      "id": "fool",
      "name": "傻瓜",
      "edition": "bmr",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "当你首次将要死亡时，你不会死亡。"
    },
    tinker: {
      "id": "tinker",
      "name": "修补工",
      "edition": "bmr",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 48,
      "otherNightReminder": "The Tinker might die.",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "你随时可能死亡。"
    },
    moonchild: {
      "id": "moonchild",
      "name": "月之子",
      "edition": "bmr",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 49,
      "otherNightReminder": "如果月之子白天指定一名玩家，如果他是善良的，他当晚死亡",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "当你得知你死亡时，你要公开选择一名存活的玩家。如果他是善良的，在当晚他会死亡。"
    },
    goon: {
      "id": "goon",
      "name": "莽夫",
      "edition": "bmr",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Drunk"],
      "setup": false,
      "ability": "每个夜晚，首个使用其自身能力选择了你的玩家会醉酒直到下个黄昏。你会转变为他的阵营。"
    },
    lunatic: {
      "id": "lunatic",
      "name": "疯子",
      "edition": "bmr",
      "team": "outsider",
      "firstNight": 7,
      "firstNightReminder": "如果有7或者超过7名玩家，给疯子一个任意的爪牙标记，玩家数等同于爪牙数目。展示任意的三个好人一方身份.如果疯子收到的身份是今夜即将苏醒的恶魔: 允许疯子执行恶魔的行动。 放置攻击标志，苏醒恶魔，展示给恶魔真正的身份。给他们展示疯子玩家，如果疯子玩家攻击其它玩家: 展示给真正的恶魔他攻击的玩家. 移除疯子的攻击标志",
      "otherNight": 21,
      "otherNightReminder": "允许疯子执行恶魔的行为。放置攻击标记。如果疯子选择了玩家，苏醒恶魔告知其疯子攻击的玩家，并移除攻击标记",
      "reminders": ["Attack 1",
          "Attack 2",
          "Attack 3"],
      "setup": false,
      "ability": "你以为你是一个恶魔，但其实你不是。恶魔知道你是什么角色以及你在每个夜晚选择了哪些玩家。"
    },
    godfather: {
      "id": "godfather",
      "name": "教父",
      "edition": "bmr",
      "team": "minion",
      "firstNight": 21,
      "firstNightReminder": "向其展示每一名外来者角色",
      "otherNight": 38,
      "otherNightReminder": "如果一名外来者今天死亡，教父指向一名玩家，该玩家死亡",
      "reminders": ["Died today",
          "Dead"],
      "setup": true,
      "ability": "在你的首个夜晚，你会得知有哪些外来者角色在场。如果有外来者在白天死亡，你会在当晚被唤醒并且你要选择一名玩家：他死亡。[-1或+1外来者]"
    },
    devilsadvocate: {
      "id": "devilsadvocate",
      "name": "魔王代言人",
      "edition": "bmr",
      "team": "minion",
      "firstNight": 22,
      "firstNightReminder": "魔王代言人指向一名存活玩家，该玩家逃脱明天的处决。",
      "otherNight": 14,
      "otherNightReminder": "魔王代言人指向一名存活玩家，该玩家逃脱明天的处决。",
      "reminders": ["Survives execution"],
      "setup": false,
      "ability": "每个夜晚，你要选择一名存活的玩家（与上个夜晚不同）：如果明天白天他被处决，他不会死亡。"
    },
    assassin: {
      "id": "assassin",
      "name": "刺客",
      "edition": "bmr",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 37,
      "otherNightReminder": "如果刺客还没发动能力，刺客可以拒绝发动或者发动能力",
      "reminders": ["Dead",
          "No ability"],
      "setup": false,
      "ability": "每局游戏限一次，在夜晚时*，你可以选择一名玩家：他死亡，即使因为任何原因让他不会死亡。"
    },
    mastermind: {
      "id": "mastermind",
      "name": "主谋",
      "edition": "bmr",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "如果恶魔因为死于处决而因此导致游戏结束时，再额外进行一个夜晚和一个白天。在那个白天如果有玩家被处决，他的阵营落败。"
    },
    zombuul: {
      "id": "zombuul",
      "name": "僵怖",
      "edition": "bmr",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 25,
      "otherNightReminder": "如果白天无人死亡，你会被唤醒，并选择一名玩家死亡",
      "reminders": ["Died today",
          "Dead"],
      "setup": false,
      "ability": "每个夜晚*，如果今天白天没有⼈死亡，你会被唤醒并要选择一名玩家：他死亡。当你首次死亡后，你仍存活，但会被当作死亡。"
    },
    pukka: {
      "id": "pukka",
      "name": "普卡",
      "edition": "bmr",
      "team": "demon",
      "firstNight": 28,
      "firstNightReminder": "选择一名玩家其中毒",
      "otherNight": 26,
      "otherNightReminder": "选择一名玩家其中毒。先前中毒的玩家死亡。",
      "reminders": ["Poisoned",
          "Dead"],
      "setup": false,
      "ability": "每个夜晚，你要选择一名玩家：他中毒。上个因你的能力中毒的玩家会死亡并恢复健康。"
    },
    shabaloth: {
      "id": "shabaloth",
      "name": "沙巴洛斯",
      "edition": "bmr",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 27,
      "otherNightReminder": "你前一夜选择过的一名玩家可以复活。再选择两名玩家他们死亡",
      "reminders": ["Dead",
          "Alive"],
      "setup": false,
      "ability": "每个夜晚*，你要选择两名玩家：他们死亡。你的上个夜晚选择过的且当前死亡的玩家可能会被你反刍"
    },
    po: {
      "id": "po",
      "name": "珀",
      "edition": "bmr",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 28,
      "otherNightReminder": "如果你上一夜没选择玩家，你可以选择三名玩家死亡。否则，你可以拒绝发动或者选择一名玩家死亡。",
      "reminders": ["Dead",
          "3 attacks"],
      "setup": false,
      "ability": "每个夜晚*，你可以选择一名玩家：他死亡。如果你上次选择时没有选择任何玩家，当晚你要选择三名玩家：他们死亡。"
    },
    apprentice: {
      "id": "apprentice",
      "name": "学徒",
      "edition": "bmr",
      "team": "traveler",
      "firstNight": 1,
      "firstNightReminder": "向其展示学徒身份，然后给予一个镇民或者爪牙身份。在魔典里用其替换学徒身份， 并在其身份处放置学徒身份",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Is the Apprentice"],
      "setup": false,
      "ability": "在你的第一个夜里，你取得一个镇民的能力(如果你是好人一方）， 或者一个爪牙的能力（如果你是邪恶一方）"
    },
    matron: {
      "id": "matron",
      "name": "女护士长",
      "edition": "bmr",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "每个白天，你可能选择至多3对玩家交换座位。玩家不能离开他们的座位私聊"
    },
    judge: {
      "id": "judge",
      "name": "法官",
      "edition": "bmr",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "游戏仅限一次，如果另一个玩家被提名， 你可能选择强制当前提名玩家被处决 或 不被处决。"
    },
    bishop: {
      "id": "bishop",
      "name": "主教",
      "edition": "bmr",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Nominate good",
          "Nominate evil"],
      "setup": false,
      "ability": "只有说书人可以提名，每个白天说书人至少要提名一名你对立阵营的玩家。"
    },
    voudon: {
      "id": "voudon",
      "name": "Voudon",
      "edition": "bmr",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "只有你和死亡玩家可以投票，不必拥有投票标记才能投票。 50%的多数规则不是必需的"
    },
    clockmaker: {
      "id": "clockmaker",
      "name": "钟表匠",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 40,
      "firstNightReminder": "Show the hand signal for the number (1, 2, 3, etc.) of places from Demon to closest Minion.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "You start knowing how many steps from the Demon to its nearest Minion."
    },
    dreamer: {
      "id": "dreamer",
      "name": "造梦师",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 41,
      "firstNightReminder": "The Dreamer points to a player. Show 1 good and 1 evil character token; one of these is correct.",
      "otherNight": 57,
      "otherNightReminder": "The Dreamer points to a player. Show 1 good and 1 evil character token; one of these is correct.",
      "reminders": [],
      "setup": false,
      "ability": "Each night, choose a player (not yourself or Travellers): you learn 1 good and 1 evil character, 1 of which is correct."
    },
    snakecharmer: {
      "id": "snakecharmer",
      "name": "耍蛇人",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 20,
      "firstNightReminder": "The Snake Charmer points to a player. If that player is the Demon: swap the Demon and Snake Charmer character and alignments. Wake each player to inform them of their new role and alignment. The new Snake Charmer is poisoned.",
      "otherNight": 12,
      "otherNightReminder": "The Snake Charmer points to a player. If that player is the Demon: swap the Demon and Snake Charmer character and alignments. Wake each player to inform them of their new role and alignment. The new Snake Charmer is poisoned.",
      "reminders": ["Poisoned"],
      "setup": false,
      "ability": "Each night, choose an alive player: a chosen Demon swaps characters & alignments with you & is then poisoned."
    },
    mathematician: {
      "id": "mathematician",
      "name": "数学家",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 51,
      "firstNightReminder": "Show the hand signal for the number (0, 1, 2, etc.) of players whose ability malfunctioned due to other abilities.",
      "otherNight": 71,
      "otherNightReminder": "Show the hand signal for the number (0, 1, 2, etc.) of players whose ability malfunctioned due to other abilities.",
      "reminders": ["Abnormal"],
      "setup": false,
      "ability": "Each night, you learn how many players\u2019 abilities worked abnormally (since dawn) due to another character's ability."
    },
    flowergirl: {
      "id": "flowergirl",
      "name": "卖花女",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 58,
      "otherNightReminder": "Nod 'yes' or shake head 'no' for whether the Demon voted today. Place the 'Demon not voted' marker (remove 'Demon voted', if any).",
      "reminders": ["Demon voted",
          "Demon not voted"],
      "setup": false,
      "ability": "Each night*, you learn if a Demon voted today."
    },
    towncrier: {
      "id": "towncrier",
      "name": "镇民",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 59,
      "otherNightReminder": "Nod 'yes' or shake head 'no' for whether a Minion nominated today. Place the 'Minion not nominated' marker (remove 'Minion nominated', if any).",
      "reminders": ["Minions not nominated",
          "Minion nominated"],
      "setup": false,
      "ability": "Each night*, you learn if a Minion nominated today."
    },
    oracle: {
      "id": "oracle",
      "name": "Oracle",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 60,
      "otherNightReminder": "Show the hand signal for the number (0, 1, 2, etc.) of dead evil players.",
      "reminders": [],
      "setup": false,
      "ability": "Each night*, you learn how many dead players are evil."
    },
    savant: {
      "id": "savant",
      "name": "博学之士",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "Each day, you may visit the Storyteller to learn 2 things in private: 1 is true & 1 is false."
    },
    seamstress: {
      "id": "seamstress",
      "name": "裁缝",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 42,
      "firstNightReminder": "The Seamstress either shows a 'no' head signal, or points to two other players. If the Seamstress chose players , nod 'yes' or shake 'no' for whether they are of same alignment.",
      "otherNight": 61,
      "otherNightReminder": "If the Seamstress has not yet used their ability: the Seamstress either shows a 'no' head signal, or points to two other players. If the Seamstress chose players , nod 'yes' or shake 'no' for whether they are of same alignment.",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "Once per game, at night, choose 2 players (not yourself): you learn if they are the same alignment."
    },
    philosopher: {
      "id": "philosopher",
      "name": "哲学家",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 2,
      "firstNightReminder": "The Philosopher either shows a 'no' head signal, or points to a good character on their sheet. If they chose a character: Swap the out-of-play character token with the Philosopher token. Or, if the character is in play, place the drunk marker by that player and the Not the Philosopher token by the Philosopher.",
      "otherNight": 2,
      "otherNightReminder": "If the Philosopher has not used their ability: the Philosopher either shows a 'no' head signal, or points to a good character on their sheet. If they chose a character: Swap the out-of-play character token with the Philosopher token. Or, if the character is in play, place the drunk marker by that player and the Not the Philosopher token by the Philosopher.",
      "reminders": ["Drunk",
          "Is the Philosopher"],
      "setup": false,
      "ability": "Once per game, at night, choose a good character: gain that ability. If this character is in play, they are drunk."
    },
    artist: {
      "id": "artist",
      "name": "艺术家",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "Once per game, during the day, privately ask the Storyteller any yes/no question."
    },
    juggler: {
      "id": "juggler",
      "name": "杂耍演员",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 62,
      "otherNightReminder": "If today was the Juggler\u2019s first day: Show the hand signal for the number (0, 1, 2, etc.) of 'Correct' markers. Remove markers.",
      "reminders": ["Correct"],
      "setup": false,
      "ability": "On your 1st day, publicly guess up to 5 players' characters. That night, you learn how many you got correct."
    },
    sage: {
      "id": "sage",
      "name": "圣人",
      "edition": "snv",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 43,
      "otherNightReminder": "If the Sage was killed by a Demon: Point to two players, one of which is that Demon.",
      "reminders": [],
      "setup": false,
      "ability": "If the Demon kills you, you learn that it is 1 of 2 players."
    },
    mutant: {
      "id": "mutant",
      "name": "变异者",
      "edition": "snv",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "If you are \u201Cmad\u201D about being an Outsider, you might be executed."
    },
    sweetheart: {
      "id": "sweetheart",
      "name": "甜心",
      "edition": "snv",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 41,
      "otherNightReminder": "Choose a player that is drunk.",
      "reminders": ["Drunk"],
      "setup": false,
      "ability": "When you die, 1 player is drunk from now on."
    },
    barber: {
      "id": "barber",
      "name": "理发师",
      "edition": "snv",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 40,
      "otherNightReminder": "If the Barber died today: Wake the Demon. Show the 'This character selected you' card, then Barber token. The Demon either shows a 'no' head signal, or points to 2 players. If they chose players: Swap the character tokens. Wake each player. Show 'You are', then their new character token.",
      "reminders": ["Haircuts tonight"],
      "setup": false,
      "ability": "If you died today or tonight, the Demon may choose 2 players (not another Demon) to swap characters."
    },
    klutz: {
      "id": "klutz",
      "name": "笨蛋",
      "edition": "snv",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "When you learn that you died, publicly choose 1 alive player: if they are evil, your team loses."
    },
    eviltwin: {
      "id": "eviltwin",
      "name": "邪恶孪生兄弟",
      "edition": "snv",
      "team": "minion",
      "firstNight": 23,
      "firstNightReminder": "Wake the Evil Twin and their twin. Confirm that they have acknowledged each other. Point to the Evil Twin. Show their Evil Twin token to the twin player. Point to the twin. Show their character token to the Evil Twin player.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Twin"],
      "setup": false,
      "ability": "You & an opposing player know each other. If the good player is executed, evil wins. Good can't win if you both live."
    },
    witch: {
      "id": "witch",
      "name": "女巫",
      "edition": "snv",
      "team": "minion",
      "firstNight": 24,
      "firstNightReminder": "The Witch points to a player. If that player nominates tomorrow they die immediately.",
      "otherNight": 15,
      "otherNightReminder": "If there are 4 or more players alive: The Witch points to a player. If that player nominates tomorrow they die immediately.",
      "reminders": ["Cursed"],
      "setup": false,
      "ability": "Each night, choose a player: if they nominate tomorrow, they die. If just 3 players live, you lose this ability."
    },
    cerenovus: {
      "id": "cerenovus",
      "name": "Cerenovus",
      "edition": "snv",
      "team": "minion",
      "firstNight": 25,
      "firstNightReminder": "The Cerenovus points to a player, then to a character on their sheet. Wake that player. Show the 'This character selected you' card, then the Cerenovus token. Show the selected character token. If the player is not mad about being that character tomorrow, they can be executed.",
      "otherNight": 16,
      "otherNightReminder": "The Cerenovus points to a player, then to a character on their sheet. Wake that player. Show the 'This character selected you' card, then the Cerenovus token. Show the selected character token. If the player is not mad about being that character tomorrow, they can be executed.",
      "reminders": ["Mad"],
      "setup": false,
      "ability": "Each night, choose a player & a good character: they are \u201Cmad\u201D they are this character tomorrow, or might be executed."
    },
    pithag: {
      "id": "pithag",
      "name": "深坑老妇",
      "edition": "snv",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 17,
      "otherNightReminder": "The Pit-Hag points to a player and a character on the sheet. If this character is not in play, wake that player and show them the 'You are' card and the relevant character token. If the character is in play, nothing happens.",
      "reminders": [],
      "setup": false,
      "ability": "Each night*, choose a player & a character they become (if not-in-play). If a Demon is made, deaths tonight are arbitrary."
    },
    fanggu: {
      "id": "fanggu",
      "name": "Fang Gu",
      "edition": "snv",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 29,
      "otherNightReminder": "The Fang Gu points to a player. That player dies. Or, if that player was an Outsider and there are no other Fang Gu in play: The Fang Gu dies instead of the chosen player. The chosen player is now an evil Fang Gu. Wake the new Fang Gu. Show the 'You are' card, then the Fang Gu token. Show the 'You are' card, then the thumb-down 'evil' hand sign.",
      "reminders": ["Dead",
          "Once"],
      "setup": true,
      "ability": "Each night*, choose a player: they die. The 1st Outsider this kills becomes an evil Fang Gu & you die instead. [+1 Outsider]"
    },
    vigormortis: {
      "id": "vigormortis",
      "name": "Vigormortis",
      "edition": "snv",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 32,
      "otherNightReminder": "The Vigormortis points to a player. That player dies. If a Minion, they keep their ability and one of their Townsfolk neighbours is poisoned.",
      "reminders": ["Dead",
          "Has ability",
          "Poisoned"],
      "setup": true,
      "ability": "Each night*, choose a player: they die. Minions you kill keep their ability & poison 1 Townsfolk neighbour. [\u22121 Outsider]"
    },
    nodashii: {
      "id": "nodashii",
      "name": "No Dashii",
      "edition": "snv",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 30,
      "otherNightReminder": "The No Dashii points to a player. That player dies.",
      "reminders": ["Dead",
          "Poisoned"],
      "setup": false,
      "ability": "Each night*, choose a player: they die. Your 2 Townsfolk neighbours are poisoned."
    },
    vortox: {
      "id": "vortox",
      "name": "Vortox",
      "edition": "snv",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 31,
      "otherNightReminder": "The Vortox points to a player. That player dies.",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "Each night*, choose a player: they die. Townsfolk abilities yield false info. Each day, if no-one is executed, evil wins."
    },
    barista: {
      "id": "barista",
      "name": "Barista",
      "edition": "snv",
      "team": "traveler",
      "firstNight": 1,
      "firstNightReminder": "Choose a player, wake them and tell them which Barista power is affecting them. Treat them accordingly (sober/healthy/true info or activate their ability twice).",
      "otherNight": 1,
      "otherNightReminder": "Choose a player, wake them and tell them which Barista power is affecting them. Treat them accordingly (sober/healthy/true info or activate their ability twice).",
      "reminders": ["Sober & Healthy",
          "Ability twice"],
      "setup": false,
      "ability": "Each night, until dusk, 1) a player becomes sober, healthy and gets true info, or 2) their ability works twice. They learn which."
    },
    harlot: {
      "id": "harlot",
      "name": "Harlot",
      "edition": "snv",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 1,
      "otherNightReminder": "The Harlot points at any player. Then, put the Harlot to sleep. Wake the chosen player, show them the 'This character selected you' token, then the Harlot token. That player either nods their head yes or shakes their head no. If they nodded their head yes, wake the Harlot and show them the chosen player's character token. Then, you may decide that both players die.",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "Each night*, choose a living player: if they agree, you learn their character, but you both might die."
    },
    butcher: {
      "id": "butcher",
      "name": "Butcher",
      "edition": "snv",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "Each day, after the 1st execution, you may nominate again."
    },
    bonecollector: {
      "id": "bonecollector",
      "name": "Bone Collector",
      "edition": "snv",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 1,
      "otherNightReminder": "The Bone Collector either shakes their head no or points at any dead player. If they pointed at any dead player, put the Bone Collector's 'Has Ability' reminder by the chosen player's character token. (They may need to be woken tonight to use it.)",
      "reminders": ["No ability",
          "Has ability"],
      "setup": false,
      "ability": "Once per game, at night, choose a dead player: they regain their ability until dusk."
    },
    deviant: {
      "id": "deviant",
      "name": "Deviant",
      "edition": "snv",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "If you were funny today, you cannot die by exile."
    },
    noble: {
      "id": "noble",
      "name": "Noble",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 43,
      "firstNightReminder": "Point to 3 players including one evil player, in no particular order.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Seen"],
      "setup": false,
      "ability": "You start knowing 3 players, 1 and only 1 of which is evil."
    },
    bountyhunter: {
      "id": "bountyhunter",
      "name": "Bounty Hunter",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 45,
      "firstNightReminder": "Point to 1 evil player. Wake the townsfolk who is evil and show them the 'You are' card and the thumbs down evil sign.",
      "otherNight": 65,
      "otherNightReminder": "If the known evil player has died, point to another evil player. ",
      "reminders": ["Known"],
      "setup": true,
      "ability": "You start knowing 1 evil player. If the player you know dies, you learn another evil player tonight. [1 Townsfolk is evil]"
    },
    pixie: {
      "id": "pixie",
      "name": "Pixie",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 29,
      "firstNightReminder": "Show the Pixie 1 in-play Townsfolk character token.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Mad",
          "Has ability"],
      "setup": false,
      "ability": "You start knowing 1 in-play Townsfolk. If you were mad that you were this character, you gain their ability when they die."
    },
    general: {
      "id": "general",
      "name": "General",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 49,
      "firstNightReminder": "Show the General thumbs up for good winning, thumbs down for evil winning or thumb to the side for neither.",
      "otherNight": 69,
      "otherNightReminder": "Show the General thumbs up for good winning, thumbs down for evil winning or thumb to the side for neither.",
      "reminders": [],
      "setup": false,
      "ability": "Each night, you learn which alignment the Storyteller believes is winning: good, evil, or neither."
    },
    preacher: {
      "id": "preacher",
      "name": "Preacher",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 14,
      "firstNightReminder": "The Preacher chooses a player. If a Minion is chosen, wake the Minion and show the 'This character selected you' card and then the Preacher token.",
      "otherNight": 7,
      "otherNightReminder": "The Preacher chooses a player. If a Minion is chosen, wake the Minion and show the 'This character selected you' card and then the Preacher token.",
      "reminders": ["At a sermon"],
      "setup": false,
      "ability": "Each night, choose a player: a Minion, if chosen, learns this. All chosen Minions have no ability."
    },
    king: {
      "id": "king",
      "name": "King",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 9,
      "firstNightReminder": "Wake the Demon, show them the 'This character selected you' card, show the King token and point to the King player.",
      "otherNight": 64,
      "otherNightReminder": "If there are more dead than living, show the King a character token of a living player.",
      "reminders": [],
      "setup": false,
      "ability": "Each night, if the dead outnumber the living, you learn 1 alive character. The Demon knows who you are."
    },
    balloonist: {
      "id": "balloonist",
      "name": "Balloonist",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 44,
      "firstNightReminder": "Choose a character type. Point to a player whose character is of that type. Place the Balloonist's Seen reminder next to that character.",
      "otherNight": 63,
      "otherNightReminder": "Choose a character type that does not yet have a Seen reminder next to a character of that type. Point to a player whose character is of that type, if there are any. Place the Balloonist's Seen reminder next to that character.",
      "reminders": ["Seen Townsfolk",
          "Seen Outsider",
          "Seen Minion",
          "Seen Demon",
          "Seen Traveller"],
      "setup": true,
      "ability": "Each night, you learn 1 player of each character type, until there are no more types to learn. [+1 Outsider]"
    },
    cultleader: {
      "id": "cultleader",
      "name": "Cult Leader",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 47,
      "firstNightReminder": "If the cult leader changed alignment, show them the thumbs up good signal of the thumbs down evil signal accordingly.",
      "otherNight": 67,
      "otherNightReminder": "If the cult leader changed alignment, show them the thumbs up good signal of the thumbs down evil signal accordingly.",
      "reminders": [],
      "setup": false,
      "ability": "Each night, you become the alignment of an alive neighbour. If all good players choose to join your cult, your team wins."
    },
    lycanthrope: {
      "id": "lycanthrope",
      "name": "Lycanthrope",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 23,
      "otherNightReminder": "The Lycanthrope points to a living player: if good, they die and no one else can die tonight.",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "Each night*, choose a living player: if good, they die, but they are the only player that can die tonight."
    },
    amnesiac: {
      "id": "amnesiac",
      "name": "Amnesiac",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 12,
      "firstNightReminder": "Decide the Amnesiac's entire ability. If the Amnesiac's ability causes them to wake tonight: Wake the Amnesiac and run their ability.",
      "otherNight": 5,
      "otherNightReminder": "If the Amnesiac's ability causes them to wake tonight: Wake the Amnesiac and run their ability.",
      "reminders": ["?"],
      "setup": false,
      "ability": "You do not know what your ability is. Each day, privately guess what it is: you learn how accurate you are."
    },
    nightwatchman: {
      "id": "nightwatchman",
      "name": "Nightwatchman",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 46,
      "firstNightReminder": "The Nightwatchman may point to a player. Wake that player, show the 'This character selected you' card and the Nightwatchman token, then point to the Nightwatchman player.",
      "otherNight": 66,
      "otherNightReminder": "The Nightwatchman may point to a player. Wake that player, show the 'This character selected you' card and the Nightwatchman token, then point to the Nightwatchman player.",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "Once per game, at night, choose a player: they learn who you are."
    },
    engineer: {
      "id": "engineer",
      "name": "Engineer",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 13,
      "firstNightReminder": "The Engineer shows a 'no' head signal, or points to a Demon or points to the relevant number of Minions. If the Engineer chose characters, replace the Demon or Minions with the choices, then wake the relevant players and show them the You are card and the relevant character tokens.",
      "otherNight": 6,
      "otherNightReminder": "The Engineer shows a 'no' head signal, or points to a Demon or points to the relevant number of Minions. If the Engineer chose characters, replace the Demon or Minions with the choices, then wake the relevant players and show them the 'You are' card and the relevant character tokens.",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "Once per game, at night, choose which Minions or which Demon is in play."
    },
    fisherman: {
      "id": "fisherman",
      "name": "Fisherman",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["No ability"],
      "setup": false,
      "ability": "Once per game, during the day, visit the Storyteller for some advice to help you win."
    },
    huntsman: {
      "id": "huntsman",
      "name": "Huntsman",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 30,
      "firstNightReminder": "The Huntsman shakes their head 'no' or points to a player. If they point to the Damsel, wake that player, show the 'You are' card and a not-in-play character token.",
      "otherNight": 51,
      "otherNightReminder": "The Huntsman shakes their head 'no' or points to a player. If they point to the Damsel, wake that player, show the 'You are' card and a not-in-play character token.",
      "reminders": ["No ability"],
      "setup": true,
      "ability": "Once per game, at night, choose a living player: the Damsel, if chosen, becomes a not-in-play Townsfolk. [+the Damsel]"
    },
    alchemist: {
      "id": "alchemist",
      "name": "Alchemist",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 3,
      "firstNightReminder": "Show the Alchemist a not-in-play Minion token",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "remindersGlobal": ["Is the Alchemist"],
      "setup": false,
      "ability": "You have a not-in-play Minion ability."
    },
    farmer: {
      "id": "farmer",
      "name": "Farmer",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 46,
      "otherNightReminder": "If a Farmer died tonight, choose another good player and make them the Farmer. Wake this player, show them the 'You are' card and the Farmer character token.",
      "reminders": [],
      "setup": false,
      "ability": "If you die at night, an alive good player becomes a Farmer."
    },
    magician: {
      "id": "magician",
      "name": "Magician",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "The Demon thinks you are a Minion. Minions think you are a Demon."
    },
    choirboy: {
      "id": "choirboy",
      "name": "Choirboy",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 44,
      "otherNightReminder": "If the King was killed by the Demon, wake the Choirboy and point to the Demon player.",
      "reminders": [],
      "setup": true,
      "ability": "If the Demon kills the King, you learn which player is the Demon. [+ the King]"
    },
    poppygrower: {
      "id": "poppygrower",
      "name": "Poppy Grower",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 4,
      "firstNightReminder": "Do not inform the Demon/Minions who each other are",
      "otherNight": 3,
      "otherNightReminder": "If the Poppy Grower has died, show the Minions/Demon who each other are.",
      "reminders": ["Evil wakes"],
      "setup": false,
      "ability": "Minions & Demons do not know each other. If you die, they learn who each other are that night."
    },
    atheist: {
      "id": "atheist",
      "name": "Atheist",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": true,
      "ability": "The Storyteller can break the game rules & if executed, good wins, even if you are dead. [No evil characters]"
    },
    cannibal: {
      "id": "cannibal",
      "name": "Cannibal",
      "edition": "",
      "team": "townsfolk",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Poisoned",
          "Died today"],
      "setup": false,
      "ability": "You have the ability of the recently killed executee. If they are evil, you are poisoned until a good player dies by execution."
    },
    snitch: {
      "id": "snitch",
      "name": "Snitch",
      "edition": "",
      "team": "outsider",
      "firstNight": 6,
      "firstNightReminder": "After Minion info wake each Minion and show them three not-in-play character tokens. These may be the same or different to each other and the ones shown to the Demon.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "Minions start knowing 3 not-in-play characters."
    },
    acrobat: {
      "id": "acrobat",
      "name": "Acrobat",
      "edition": "",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 39,
      "otherNightReminder": "If a good living neighbour is drunk or poisoned, the Acrobat player dies.",
      "reminders": ["Dead"],
      "setup": false,
      "ability": "Each night*, if either good living neighbour is drunk or poisoned, you die."
    },
    puzzlemaster: {
      "id": "puzzlemaster",
      "name": "Puzzlemaster",
      "edition": "",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Drunk",
          "Guess used"],
      "setup": false,
      "ability": "1 player is drunk, even if you die. If you guess (once) who it is, learn the Demon player, but guess wrong & get false info."
    },
    heretic: {
      "id": "heretic",
      "name": "Heretic",
      "edition": "",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "Whoever wins, loses & whoever loses, wins, even if you are dead."
    },
    damsel: {
      "id": "damsel",
      "name": "Damsel",
      "edition": "",
      "team": "outsider",
      "firstNight": 31,
      "firstNightReminder": "Wake all the Minions, show them the 'This character selected you' card and the Damsel token.",
      "otherNight": 52,
      "otherNightReminder": "If selected by the Huntsman, wake the Damsel, show 'You are' card and a not-in-play Townsfolk token.",
      "reminders": ["Guess used"],
      "setup": false,
      "ability": "All Minions know you are in play. If a Minion publicly guesses you (once), your team loses."
    },
    golem: {
      "id": "golem",
      "name": "Golem",
      "edition": "",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Can not nominate"],
      "setup": false,
      "ability": "You may only nominate once per game. When you do, if the nominee is not the Demon, they die."
    },
    politician: {
      "id": "politician",
      "name": "Politician",
      "edition": "",
      "team": "outsider",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "If you were the player most responsible for your team losing, you change alignment & win, even if dead."
    },
    widow: {
      "id": "widow",
      "name": "Widow",
      "edition": "",
      "team": "minion",
      "firstNight": 18,
      "firstNightReminder": "Show the Grimoire to the Widow for as long as they need. The Widow points to a player. That player is poisoned. Wake a good player. Show the 'These characters are in play' card, then the Widow character token.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Poisoned"],
      "remindersGlobal": ["Knows"],
      "setup": false,
      "ability": "On your 1st night, look at the Grimoire and choose a player: they are poisoned. 1 good player knows a Widow is in play."
    },
    fearmonger: {
      "id": "fearmonger",
      "name": "Fearmonger",
      "edition": "",
      "team": "minion",
      "firstNight": 26,
      "firstNightReminder": "The Fearmonger points to a player. Place the Fear token next to that player and announce that a new player has been selected with the Fearmonger ability.",
      "otherNight": 18,
      "otherNightReminder": "The Fearmonger points to a player. If different from the previous night, place the Fear token next to that player and announce that a new player has been selected with the Fearmonger ability.",
      "reminders": ["Fear"],
      "setup": false,
      "ability": "Each night, choose a player. If you nominate & execute them, their team loses. All players know if you choose a new player."
    },
    psychopath: {
      "id": "psychopath",
      "name": "Psychopath",
      "edition": "",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "Each day, before nominations, you may publicly choose a player: they die. If executed, you only die if you lose roshambo."
    },
    goblin: {
      "id": "goblin",
      "name": "Goblin",
      "edition": "",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": ["Claimed"],
      "setup": false,
      "ability": "If you publicly claim to be the Goblin when nominated & are executed that day, your team wins."
    },
    mephit: {
      "id": "mephit",
      "name": "Mephit",
      "edition": "",
      "team": "minion",
      "firstNight": 27,
      "firstNightReminder": "Show the Mephit their secret word.",
      "otherNight": 19,
      "otherNightReminder": "Wake the 1st good player that said the Mephit's secret word and show them the 'You are' card and the thumbs down evil signal.",
      "reminders": ["Turns evil",
          "No ability"],
      "setup": false,
      "ability": "You start knowing a secret word. The 1st good player to say this word becomes evil that night."
    },
    mezepheles: {
      "id": "mezepheles",
      "name": "Mezepheles",
      "edition": "",
      "team": "minion",
      "firstNight": 27,
      "firstNightReminder": "Show the Mezepheles their secret word.",
      "otherNight": 19,
      "otherNightReminder": "Wake the 1st good player that said the Mezepheles' secret word and show them the 'You are' card and the thumbs down evil signal.",
      "reminders": ["Turns evil",
          "No ability"],
      "setup": false,
      "ability": "You start knowing a secret word. The 1st good player to say this word becomes evil that night."
    },
    marionette: {
      "id": "marionette",
      "name": "Marionette",
      "edition": "",
      "team": "minion",
      "firstNight": 11,
      "firstNightReminder": "Select one of the good players next to the Demon and place the Is the Marionette reminder token. Wake the Demon and show them the Marionette.",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "remindersGlobal": ["Is the Marionette"],
      "setup": true,
      "ability": "You think you are a good character but you are not. The Demon knows who you are. [You neighbour the Demon]"
    },
    boomdandy: {
      "id": "boomdandy",
      "name": "Boomdandy",
      "edition": "",
      "team": "minion",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "If you are executed, all but 3 players die. 1 minute later, the player with the most players pointing at them dies."
    },
    lilimonsta: {
      "id": "lilmonsta",
      "name": "Lil' Monsta",
      "edition": "",
      "team": "demon",
      "firstNight": 16,
      "firstNightReminder": "Wake all Minions together, allow them to vote by pointing at who they want to babysit Lil' Monsta.",
      "otherNight": 36,
      "otherNightReminder": "Wake all Minions together, allow them to vote by pointing at who they want to babysit Lil' Monsta. Choose a player, that player dies.",
      "reminders": [],
      "remindersGlobal": ["Is the Demon",
          "Dead"],
      "setup": true,
      "ability": "Each night, Minions choose who babysits Lil' Monsta's token & \"is the Demon\". A player dies each night*. [+1 Minion]"
    },
    lleech: {
      "id": "lleech",
      "name": "Lleech",
      "edition": "",
      "team": "demon",
      "firstNight": 15,
      "firstNightReminder": "The Lleech points to a player. Place the Poisoned reminder token.",
      "otherNight": 35,
      "otherNightReminder": "The Lleech points to a player. That player dies.",
      "reminders": ["Dead",
          "Poisoned"],
      "setup": false,
      "ability": "Each night*, choose a player: they die. You start by choosing an alive player: they are poisoned - you die if & only if they die."
    },
    alhadikhia: {
      "id": "alhadikhia",
      "name": "Al-Hadikhia",
      "edition": "",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 33,
      "otherNightReminder": "The Al-Hadikhia chooses 3 players. Announce the first player, wake them to nod yes to live or shake head no to die, kill or resurrect accordingly, then put to sleep and announce the next player. If all 3 are alive after this, all 3 die.",
      "reminders": ["1", "2", "3",
          "Chose death",
          "Chose life"],
      "setup": false,
      "ability": "Each night*, choose 3 players (all players learn who): each silently chooses to live or die, but if all live, all die."
    },
    legion: {
      "id": "legion",
      "name": "Legion",
      "edition": "",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 34,
      "otherNightReminder": "Choose a player, that player dies.",
      "reminders": ["Dead",
          "About to die"],
      "setup": true,
      "ability": "Each night*, a player might die. Executions fail if only evil voted. You register as a Minion too. [Most players are Legion]"
    },
    leviathan: {
      "id": "leviathan",
      "name": "Leviathan",
      "edition": "",
      "team": "demon",
      "firstNight": 53,
      "firstNightReminder": "Place the Leviathan 'Day 1' marker. Announce 'The Leviathan is in play; this is Day 1.'",
      "otherNight": 73,
      "otherNightReminder": "Change the Leviathan Day reminder for the next day.",
      "reminders": ["Day 1",
          "Day 2",
          "Day 3",
          "Day 4",
          "Day 5",
          "Good player executed"],
      "setup": false,
      "ability": "If more than 1 good player is executed, you win. All players know you are in play. After day 5, evil wins."
    },
    riot: {
      "id": "riot",
      "name": "Riot",
      "edition": "",
      "team": "demon",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": true,
      "ability": "Nominees die, but may nominate again immediately (on day 3, they must). After day 3, evil wins. [All Minions are Riot]"
    },
    gangster: {
      "id": "gangster",
      "name": "Gangster",
      "edition": "",
      "team": "traveler",
      "firstNight": 0,
      "firstNightReminder": "",
      "otherNight": 0,
      "otherNightReminder": "",
      "reminders": [],
      "setup": false,
      "ability": "Once per day, you may choose to kill an alive neighbour, if your other alive neighbour agrees."
    }
  },
  // components
  menu: {
    hide: "隐藏",
    show: "显示",
    switchToNight: "切换到夜晚",
    switchToDay: "切换到白天",
    nightOrder: "夜间顺序",
    zoom: "缩放",
    backgroundImage: "背景图片",
    showCustomImages: "展示Custom图片",
    disableAminations: "禁用动画",
    muteSounds: "禁用声音",
    liveSession: "当前游戏",
    copyPlayerLink: "复制玩家链接",
    sendCharacters: "发送角色",
    voteHistory: "投票历史",
    leaveSession: "离开游戏",
    delayTo: "延迟",
    Randomize: "随机座位",
    RemoveAll: "移除所有座位",
    SelectEdition: "选择版本",
    ChooseAssign: "选择并分配",
    AddFabled: "增加传奇角色",
    ReferenceSheet: "角色能力表",
    NightOrderSheet: "夜间顺序表",
    GameStateJSON: "游戏状态JSON",
    confirmAllowCustomImage: "确认允许自定义图片?脚本作者可能据此跟您的IP",
    confirmLeaveGame: "确认离开当前游戏？",
    confirmRandomizeSeating: "确认随机初始化座位吗？",
    confirmRemoveAllPlayers: "确认移除所有玩家？",
    confirmRemoveAllRoles: "确认移除所有玩家角色？",
    distributeChars: "确认给所有落座玩家分配角色？",
    enterBackgroundURL: "请输入自定义背景URL",
    enterChannelNumber: "请输入当前游戏的频道号码或名称",
    enterChannelNumberToJoin: "请输入要加入游戏的频道号码或者名称",
    playerName: "玩家名字",
    playerPronouns: "玩家发音"
  },
  playerCom: {
    ChangePronouns: "改变发音",
    Rename: "重命名",
    MovePlayer: "移动玩家",
    SwapSeats: "交换座位",
    Remove: "移除",
    EmptySeat: "清空座位",
    Nomination: "提名",
    ClaimSeat: "占座",
    VacateSeat: "空出座位",
    SeatOccupied: "座位被占据"
  },
  vote: {
    vote: "投票",
    nominated: "被提名",
    majorityIs: "大多数是",
    timePerPlayer: "每名玩家的时间:",
    markForExecution: "标记处决",
    clearMark: "清理标记",
    secondsBetweenVotes: "秒在投票之间",
    HandDown: "放下手",
    HandUp: "举手",
    PleaseClaimASeatToVote: "请占座投票",
    inFavor: "赞成"
  },
  editionModal: {
    SelectAnEdition: "选择一个版本",
    CustomScriptCharacters: "自定义脚本/角色",
    LoadCustomScriptCharacters: "加载自定义脚本/角色",
  },
  fabledModal: {
    ChooseAFabledChar: "选择一个传奇角色加入游戏",
  },
  gameStateModal: {
    CurrentGameState: "当前游戏状态",
    CopyJSON: "复制JSON",
    LoadState: "载入状态"
  },
  referModal: {
    CharRefer: "角色参考",
    Jinxed: "倒霉"
  },
  reminderModal: {
    ChooseAReminderToken: "选择一个备忘标记",
    AddCustomNote: "增加自定义标记"
  },
  roleModal:{
    ChooseANewCharFor: "选择一个新角色给",
    EditionRoles: "版本角色",
    OtherTravelers: "其它旅行者",
    bluffing: "窃窃私语"
  },
  rolesModal: {
    SelectCharsForPlayers: "为{nonTravelers}个玩家选择角色:",
    Warning: "提醒： 有被选择的角色修改了游戏配置! 随机化对这些角色不起作用。",
    AllowDupChar: "允许重复的角色",
    AssignCharsRandomly: "随机分配{selectedRoles}个角色",
    ShuffleChars: "打乱角色"
  },
  voteHistoryModal: {
    VoteHistory: "投票历史",
    AccessibleToPlayers: "对玩家可访问",
    ClearForEveryone: "为每个人清理",
    Time: "时间",
    Nominator: "提名者",
    Nominee: "被提名者",
    Type: "类型",
    Votes: "投票",
    Majority: "大多数",
    Voters: "投票者"
  },
  townInfo: {
    AddMorePlayers: "请增加更多选手!",
    NightPhase: "夜间阶段"
  },
  townSquare: {
    OtherCharacters: "其它角色",
    DemonBluffs: "恶魔得知的不在场角色",
    Fabled: "传奇",
    RemovePlayerText: "你确定移除玩家"
  },
  nightOrderModal: {
    MinionInfo: "爪牙信息",
    DemonInfo: "恶魔信息及私下讨论",
    MinionInfoFirstNightReminder: "• 如有超过一个爪牙, 他们互相睁眼认识。 " +
      "• 向他们展示谁是恶魔。",
    DemonInfoFirstNightReminder: "• 向恶魔展示每一个爪牙。" +
      "• 展示哪些角色没有上场。展示没有上场的三个好人阵营的角色" 
  },
  reminders: {
    Grandchild: "孙子",
    Protected: "受保护",
    Drunk: "醉酒",
    Dead: "死亡",
    "Drunk 3": "醉酒3",
    "Drunk 2": "醉酒2",
    "Drunk 1": "醉酒1",
    "No ability": "失去能力",
    "Everyone drunk": "所有人醉酒",
    "Can not die": "不能死亡",
    "Survives execution": "免于处决",
    Poisoned: "被毒",
    Good: "好人",
    Evil: "坏人",
    Alive: "存活",
    "Died today": "今天死亡",
    Chosen: "被选择",
    "Custom note": "自定义标记",
    Minion: "爪牙",
    Wrong: "错误",
    "Red herring": "无关紧要",
    Outsider: "外来者",
    Demon: "恶魔",
    "Attack 1": "攻击1",
    "Attack 2": "攻击2",
    "Attack 3": "攻击3",
    Abnormal: "异常",
    "Minion nominated": "爪牙提名",
    "Minions not nominated": "爪牙未提名",
    "Is the Philosopher": "哲学家",
    Correct: "正确",
    "Haircuts tonight": "今晚理发",
    Mad: "疯狂",
    Cursed: "被诅咒",
    "Demon voted": "恶魔投票",
    "Demon not voted": "恶魔未投票",
    "Has ability": "拥有能力",
    "Twin": "双胞胎",
    Townsfolk: "镇民",
    Executed: "被处决",
    Master: "主人",
    Once: "一次",
    "Day 1": "第1天",
    "Day 2": "第2天",
    "Day 3": "第3天",
    "Day 4": "第4天",
    "Day 5": "第5天",
    "Seen Townsfolk": "被看到的镇民",
    "Seen Outsider": "被看到的外来者",
    "Seen Minion": "被看到的爪牙",
    "Seen Demon": "被看到的恶魔",
    "Seen Traveller": "被看到的旅行者",
    "?": "?",
    "Knows": "知晓",
    "Claimed": "声明",
    "Good player executed": "善良玩家被处决",
    "Final Night: No Attack": "最终夜： 没有攻击",
    "3 attacks": "攻击三人"
  }
};

export default messages;
